/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

@import "/src/theme/variables.scss";
@import '/src/theme/animations.scss';


* {
	font-family: "roboto", sans-serif;

}

h1 {
	font-family: "blockhead-black-face", sans-serif;
	font-style: normal;
	color: var(--ion-color-text1);
}
h2 {
	font-family: "montserrat", sans-serif;
	font-weight: 900;
	font-size: 28px;
	font-style: normal;
	color: var(--ion-color-text1);
}
h3 {
	color: var(--ion-color-text2);
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 18px;
	font-style: normal;
	color: var(--ion-color-text2);
}
ion-title {
	font-family: "blockhead-black-face", sans-serif;
	// font-weight: 400;
	font-style: normal;
	font-size: 25px;
	color: var(--ion-color-primary);
    font-weight: normal !important;

}
ion-list-header {
	font-family: "blockhead-black-face", sans-serif;
	// font-weight: 400;
	font-style: normal;
}
p {
	color: var(--ion-color-text3);

}
.bold {
	font-weight: 800;
}
.my-small {
	font-size: 70%;
}

.montserrat {
	font-family: "montserrat", sans-serif;
	font-weight: 800;
	font-style: normal;
}
.blockhead-black-face {
	font-family: "blockhead-black-face", sans-serif;
	font-weight: 400;
	font-style: normal;
}
.blockhead-dark-side {
	font-family: "blockhead-dark-side", sans-serif;
	font-weight: 400;
	font-style: normal;
}
.blockhead-plain {
	font-family: "blockhead-plain", sans-serif;
	font-weight: 400;
	font-style: normal;
}
.blockhead-unplugged {
	font-family: "blockhead-unplugged", sans-serif;
	font-weight: 400;
	font-style: normal;
}









// placed here instead of intro.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}

ion-footer {
  	// background-color :red !important;
  	background-color : var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
}

ion-card {
	margin: 1rem !important;
}

ion-item {
	.icon-container {
		margin-top: 35px !important;
		i {
			opacity: .5;
		}
	}
}

.pointer {
	cursor: pointer;
}

ion-input {
	// transition: .1s;

	// &.has-focus {
		color: var(--ion-color-dark) !important
	// }
}
ion-icon.disabled  {
	opacity: .5;
	pointer-events: none;
}

.separator {
    width: 100%;
    margin: 1rem 0rem;
    border-top: 1px solid var(--ion-color-accent);
}


// Spacing
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.responsive-flex-between {
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        display: block !important;
    }
}

.flex-start,
.flex-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-end,
.flex-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.wrap { flex-wrap: wrap; }

.text-right {
    text-align: right;
}

.primary {
	color: var(--ion-color-primary) !important;
}
.link {
	color: var(--ion-color-link) !important;
}
.text1 {
	color: var(--ion-color-text1) !important;
}
.text2 {
	color: var(--ion-color-text2) !important;
}
.text3 {
	color: var(--ion-color-text3) !important;
}
.text4 {
	color: var(--ion-color-text4) !important;
}


input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    border: 1px solid var(--ion-color-link);
}

input, textarea {
    background-color: var(--ion-background-color);
    border: 1px solid var(--ion-color-accent);
    padding-inline-start: 12px;
    color: var(--ion-color-text1);
    border-radius: 4px;
	// width: 100%;
}
input {
	padding: 5px 8px;
}
textarea {
	padding: .5rem.75rem;
}

select {
    background-color: var(--ion-background-color) !important;
    color: var(--ion-color-text1) !important;
    border: 1px solid var(--ion-color-accent) !important;
    border-radius: 4px !important; 
}

.my-toast {
	transform: translateY(-60px) !important;
}
.ios .my-notify {
	transform: translateY(44px) !important;
}
.md .my-notify {
	transform: translateY(56px) !important;
}
ion-toast::part(header) {
	font-weight: 800;
	font-family: "montserrat", sans-serif;
}
ion-toast::part(message) {
	padding: 0 !important;
}
// ion-toast::part(button) {
// 	// padding-right: .5rem !important
// }

.hidden {
	opacity: 0 !important;
}
.block {
	display: block!important;
}

.my-popover {
	--width : 250px;
	
}

.blur-container {
    position: relative;
    z-index: 500;
    &.blurred {
        filter: blur(5px);
        pointer-events: none;
    }
}


ion-title.title-large {
    // color: #c897d8;
    font-size: 30px;
}
  
// ion-title {
//     --color: #000;
// }
  
ion-toolbar {
    ion-title {
        padding-bottom: 8px;
        // --color: #000;
    }
    // --background: var(--ion-color-secondary);
}
.my-small {
    font-size: 80% !important;
}

.alert-message.sc-ion-alert-ios, .alert-message.sc-ion-alert-md {
    max-height: max-content !important;
} 
.white {
    color: white !important;
}

.my-alert {
    background-color: rgb(var(--ion-color-primary-rgb), .2);
    border: 1px solid rgb(var(--ion-color-primary-rgb), .4);
    padding: 0.25rem .75rem;
    border-radius: 10px;
    width: 100%;
    color: var(--text-color-2);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
        font-size: 60px;
        margin-right: 1rem;
    }

    font-style: italic;

    &.success {
        background-color: rgb(var(--ion-color-success-rgb), .2);
        border: 1px solid rgb(var(--ion-color-success-rgb), .4);
    }

    &.danger {
        background-color: rgb(var(--ion-color-danger-rgb), .2);
        border: 1px solid rgb(var(--ion-color-danger-rgb), .4);
    }

    &.warning {
        background-color: rgb(var(--ion-color-warning-rgb), .2);
        border: 1px solid rgb(var(--ion-color-warning-rgb), .4);
    }
}