@import './variables.scss';


.trans { transition: .3s; }

// Animations
.floating-glow {
    transform: translatey(0px);
	animation: float-glow 6s ease-in-out infinite;
}
// Mobile Styling
@media(max-width:991px){
    @keyframes float-glow {
        0% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
        50% {
            filter: drop-shadow(0 10px 15px rgba(97, 120, 255, .5));
            transform: translatey(-3px) rotate(0deg);
        }
        100% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
    }
}
// Desktop styling
@media(min-width:992px){
    @keyframes float-glow {
        0% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
        50% {
            filter: drop-shadow(0 10px 15px rgba(97, 120, 255, .5));
            transform: translatey(-8px) rotate(0deg);
        }
        100% {
            filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
            transform: translatey(0px) rotate(0deg);
        }
    }
}
.floating {
    transform: translatey(-3px);
	animation: float 6s ease-in-out infinite;
}
@keyframes float {
    0% {
        // filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(-3px) rotate(0deg);
    }
    50% {
        // filter: drop-shadow(0 10px 15px rgba(97, 120, 255, .5));
        transform: translatey(3px) rotate(0deg);
    }
    100% {
        // filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(-3px) rotate(0deg);
    }
}

.floating-small {
    transform: translatey(0px);
	animation: float-small 4s ease-in-out infinite;
}
@keyframes float-small {
    0% {
        filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(1.5px) rotate(-2deg);
    }
    50% {
        filter: drop-shadow(0 4px 15px rgba(97, 120, 255, .5));
        transform: translatey(-1.5px) rotate(2deg);
    }
    100% {
        filter: drop-shadow(0 0 15px rgba(97, 120, 255, 1));
        transform: translatey(1.5px) rotate(-2deg);
    }
}

@-webkit-keyframes translate-left-in {
    0%   { -webkit-transform:translateX(-20px); }
    100% { -webkit-transform:translateX(0); }
}
@keyframes translate-left-in {
    0%   { transform:translateX(-20px); }
    100% { transform:translateX(0); }
}

@-webkit-keyframes translate-left-out {
    0%   { -webkit-transform:translateX(0%); }
    100% { -webkit-transform:translateX(20px); }
}
@keyframes translate-left-out {
    0%   { transform:translateX(0%); }
    100% { transform:translateX(20px); }
}


// ANIMATIONS
.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInUp {
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}
@-webkit-keyframes fadeInUp {
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}
.fadeInRight {
    opacity: 0;
    animation-name: fadeInRight;
    -webkit-animation-name: fadeInRight;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInRight {
    from { transform: translate3d(-40px,0,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}
@-webkit-keyframes fadeInRight {
    from { transform: translate3d(-40px,0,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}


.slideInUp {
    animation-name: slideInUp;
    -webkit-animation-name: slideInUp;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes slideInUp {
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); }
}
@-webkit-keyframes slideInUp {
    from { transform: translate3d(0,40px,0) }
    to { transform: translate3d(0,0,0); }
}

.slideInRight {
    animation-name: slideInRight;
    -webkit-animation-name: slideInRight;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes slideInRight {
    from { transform: translate3d(-100px,0,0) }
    to { transform: translate3d(0,0,0); }
}
@-webkit-keyframes slideInRight {
    from { transform: translate3d(-100px,0,0) }
    to { transform: translate3d(0,0,0); }
}

.fadeInDown {
    opacity: 0;
    animation-name: fadeInDown;
    -webkit-animation-name: fadeInDown;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}
@-webkit-keyframes fadeInDown {
    from { transform: translate3d(0,-40px,0) }
    to { transform: translate3d(0,0,0); opacity: 1 }
}

.fadeIn {
    opacity: 0;
    animation-name: fadeIn;
    -webkit-animation-name: fadeIn;

    animation-duration: .3s;
    animation-fill-mode: both;
    -webkit-animation-duration: .3s;
    -webkit-animation-fill-mode: both;
}
@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}

.downUp {
    position: relative;
    animation: downUp 2s ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation: downUp 2s ease-in-out;
    -webkit-animation-iteration-count: infinite;
}
@keyframes downUp {
    0%   { top:0; }
    15%  { top:15px; } 
    30%  { top:0%; } 
}
@-webkit-keyframes downUp {
    0%   { top:0; }
    15%  { top:15px; } 
    30%  { top:0%; } 
}
.upDown {
    position: relative;
    animation: upDown 2s ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation: upDown 2s ease-in-out;
    -webkit-animation-iteration-count: infinite;
}
@keyframes upDown {
    0%   { bottom:0; }
    15%  { bottom:15px; } 
    30%  { bottom:0%; } 
}
@-webkit-keyframes upDown {
    0%   { bottom:0; }
    15%  { bottom:15px; } 
    30%  { bottom:0%; } 
}


.delay-child {
    $interval: 0.05s;
    @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
            animation-delay: ($i - 1) * $interval;
        }
    }
}
.delay-child-fast {
    $interval: 0.025s;
    @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
            animation-delay: ($i - 1) * $interval;
        }
    }
}
// .delay-child {
//     &:nth-child(1) { animation-delay: .05s }
//     &:nth-child(2) { animation-delay: .1s }
//     &:nth-child(3) { animation-delay: .15s }
//     &:nth-child(4) { animation-delay: .2s }
//     &:nth-child(5) { animation-delay: .25s }
//     &:nth-child(6) { animation-delay: .3s }
//     &:nth-child(7) { animation-delay: .35s }
//     &:nth-child(8) { animation-delay: .4s }
//     &:nth-child(9) { animation-delay: .45s }
//     &:nth-child(10) { animation-delay: .5s }
//     &:nth-child(11) { animation-delay: .55s }
//     &:nth-child(12) { animation-delay: .6s }
//     &:nth-child(13) { animation-delay: .65s }
//     &:nth-child(14) { animation-delay: .7s }
//     &:nth-child(15) { animation-delay: .75s }
// }
// .delay-child-fast {
//     &:nth-child(1) { animation-delay: .025s }
//     &:nth-child(2) { animation-delay: .05s }
//     &:nth-child(3) { animation-delay: .075s }
//     &:nth-child(4) { animation-delay: .1s }
//     &:nth-child(5) { animation-delay: .125s }
//     &:nth-child(6) { animation-delay: .15s }
//     &:nth-child(7) { animation-delay: .175s }
//     &:nth-child(8) { animation-delay: .2s }
//     &:nth-child(9) { animation-delay: .225s }
//     &:nth-child(10) { animation-delay: .25s }
//     &:nth-child(11) { animation-delay: .275s }
//     &:nth-child(12) { animation-delay: .3s }
//     &:nth-child(13) { animation-delay: .325s }
//     &:nth-child(14) { animation-delay: .35s }
//     &:nth-child(15) { animation-delay: .375s }
// }
.delay-child-group1 {
    .delay-child {
        &:nth-child(1) { animation-delay: .05s }
        &:nth-child(2) { animation-delay: .1s }
        &:nth-child(3) { animation-delay: .15s }
        &:nth-child(4) { animation-delay: .2s }
        &:nth-child(5) { animation-delay: .25s }
        &:nth-child(6) { animation-delay: .3s }
        &:nth-child(7) { animation-delay: .35s }
        &:nth-child(8) { animation-delay: .4s }
        &:nth-child(9) { animation-delay: .45s }
        &:nth-child(10) { animation-delay: .5s }
        &:nth-child(11) { animation-delay: .55s }
        &:nth-child(12) { animation-delay: .6s }
        &:nth-child(13) { animation-delay: .65s }
        &:nth-child(14) { animation-delay: .7s }
        &:nth-child(15) { animation-delay: .75s }
    }
}
.delay-child-group2 {
    .delay-child {
        &:nth-child(1) { animation-delay: .05s }
        &:nth-child(2) { animation-delay: .1s }
        &:nth-child(3) { animation-delay: .15s }
        &:nth-child(4) { animation-delay: .2s }
        &:nth-child(5) { animation-delay: .25s }
        &:nth-child(6) { animation-delay: .3s }
        &:nth-child(7) { animation-delay: .35s }
        &:nth-child(8) { animation-delay: .4s }
        &:nth-child(9) { animation-delay: .45s }
        &:nth-child(10) { animation-delay: .5s }
        &:nth-child(11) { animation-delay: .55s }
        &:nth-child(12) { animation-delay: .6s }
        &:nth-child(13) { animation-delay: .65s }
        &:nth-child(14) { animation-delay: .7s }
        &:nth-child(15) { animation-delay: .75s }
    }
}

.delay1 { animation-delay: .05s; }
.delay2 { animation-delay: .1s; }
.delay3 { animation-delay: .15s; }
.delay4 { animation-delay: .2s; }
.delay5 { animation-delay: .25s; }
.delay6 { animation-delay: .3s; }
.delay7 { animation-delay: .35s; }
.delay8 { animation-delay: .4s; }
.delay9 { animation-delay: .45s; }
.delay10 { animation-delay: .5s; }
.delay11 { animation-delay: .6s; }
.delay12 { animation-delay: .7s; }
.delay13 { animation-delay: .8s; }
.delay14 { animation-delay: .9s; }
.delay15 { animation-delay: 1s; }
.delay16 { animation-delay: 1.1s; }
.delay20 { animation-delay: 2s; }

.duration1 { animation-duration: .5s !important; }
.duration2 { animation-duration: .75s !important; }
.duration3 { animation-duration: 1s !important; }
.duration4 { animation-duration: 1.25s !important; }


.my-shake {
    animation: shake .4s;
    animation-iteration-count: 1;
}

.my-shake-continue {
    animation: shake .4s infinite;
}
  
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes rotateX {
    0% {
        opacity: 0;
        transform: rotateX(-90deg);
    }
    50% {
        transform: rotateX(-20deg);
    }
    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.zoomIn {
    opacity: 0;
    animation-name: zoomIn;
    -webkit-animation-name: zoomIn;

    animation-duration: .2s;
    animation-fill-mode: both;
    -webkit-animation-duration: .2s;
    -webkit-animation-fill-mode: both;
}
@keyframes zoomIn {
    0% {
       opacity: 0;
       transform: scale(0.90);
    }
    50% {
       opacity: 1;
       transform: scale(1.1);
    }
    100% {
       opacity: 1;
       transform: scale(1);
    }
 } 
.zoomInSmall {
    opacity: 0;
    animation-name: zoomInSmall;
    -webkit-animation-name: zoomInSmall;

    animation-duration: .2s;
    animation-fill-mode: both;
    -webkit-animation-duration: .2s;
    -webkit-animation-fill-mode: both;
}
@keyframes zoomInSmall {
    0% {
       opacity: 0;
       transform: scale(0.95);
    }
    50% {
       opacity: 1;
       transform: scale(1.02);
    }
    100% {
       opacity: 1;
       transform: scale(1);
    }
 } 




//  FANCY SPINNER
/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .la-ball-circus,
 .la-ball-circus > div {
     position: relative;
     -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
             box-sizing: border-box;
 }
 
 .la-ball-circus {
     display: block;
     font-size: 0;
     color: #5865F2;
 }
 
 .la-ball-circus.la-dark {
     color: #333;
 }
 
 .la-ball-circus > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 
 .la-ball-circus {
     width: 16px;
     height: 16px;
 }
 
 .la-ball-circus > div {
     position: absolute;
     top: 0;
     left: -100%;
     display: block;
     width: 16px;
     width: 100%;
     height: 16px;
     height: 100%;
     border-radius: 100%;
     opacity: .5;
     -webkit-animation: ball-circus-position 2.5s infinite cubic-bezier(.25, 0, .75, 1), ball-circus-size 2.5s infinite cubic-bezier(.25, 0, .75, 1);
        -moz-animation: ball-circus-position 2.5s infinite cubic-bezier(.25, 0, .75, 1), ball-circus-size 2.5s infinite cubic-bezier(.25, 0, .75, 1);
          -o-animation: ball-circus-position 2.5s infinite cubic-bezier(.25, 0, .75, 1), ball-circus-size 2.5s infinite cubic-bezier(.25, 0, .75, 1);
             animation: ball-circus-position 2.5s infinite cubic-bezier(.25, 0, .75, 1), ball-circus-size 2.5s infinite cubic-bezier(.25, 0, .75, 1);
 }
 
 .la-ball-circus > div:nth-child(1) {
     -webkit-animation-delay: 0s, -.5s;
        -moz-animation-delay: 0s, -.5s;
          -o-animation-delay: 0s, -.5s;
             animation-delay: 0s, -.5s;
 }
 
 .la-ball-circus > div:nth-child(2) {
     -webkit-animation-delay: -.5s, -1s;
        -moz-animation-delay: -.5s, -1s;
          -o-animation-delay: -.5s, -1s;
             animation-delay: -.5s, -1s;
 }
 
 .la-ball-circus > div:nth-child(3) {
     -webkit-animation-delay: -1s, -1.5s;
        -moz-animation-delay: -1s, -1.5s;
          -o-animation-delay: -1s, -1.5s;
             animation-delay: -1s, -1.5s;
 }
 
 .la-ball-circus > div:nth-child(4) {
     -webkit-animation-delay: -1.5s, -2s;
        -moz-animation-delay: -1.5s, -2s;
          -o-animation-delay: -1.5s, -2s;
             animation-delay: -1.5s, -2s;
 }
 
 .la-ball-circus > div:nth-child(5) {
     -webkit-animation-delay: -2s, -2.5s;
        -moz-animation-delay: -2s, -2.5s;
          -o-animation-delay: -2s, -2.5s;
             animation-delay: -2s, -2.5s;
 }
 
 .la-ball-circus.la-sm {
     width: 8px;
     height: 8px;
 }
 
 .la-ball-circus.la-sm > div {
     width: 8px;
     height: 8px;
 }
 
 .la-ball-circus.la-2x {
     width: 32px;
     height: 32px;
 }
 
 .la-ball-circus.la-2x > div {
     width: 32px;
     height: 32px;
 }
 
 .la-ball-circus.la-3x {
     width: 48px;
     height: 48px;
 }
 
 .la-ball-circus.la-3x > div {
     width: 48px;
     height: 48px;
 }
 
 /*
  * Animations
  */
 @-webkit-keyframes ball-circus-position {
     50% {
         left: 100%;
     }
 }
 @-moz-keyframes ball-circus-position {
     50% {
         left: 100%;
     }
 }
 @-o-keyframes ball-circus-position {
     50% {
         left: 100%;
     }
 }
 @keyframes ball-circus-position {
     50% {
         left: 100%;
     }
 }
 
 @-webkit-keyframes ball-circus-size {
     50% {
         -webkit-transform: scale(.3, .3);
                 transform: scale(.3, .3);
     }
 }
 
 @-moz-keyframes ball-circus-size {
     50% {
         -moz-transform: scale(.3, .3);
              transform: scale(.3, .3);
     }
 }
 
 @-o-keyframes ball-circus-size {
     50% {
         -o-transform: scale(.3, .3);
            transform: scale(.3, .3);
     }
 }
 
 @keyframes ball-circus-size {
     50% {
         -webkit-transform: scale(.3, .3);
            -moz-transform: scale(.3, .3);
              -o-transform: scale(.3, .3);
                 transform: scale(.3, .3);
     }
 }
 

.blink {
    -webkit-animation: blink 2s infinite both;
            animation: blink 2s infinite both;
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}