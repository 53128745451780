/*
 * Ionic Variables and Theming
 * ----------------------------------------------------------------------------
 * For more information, please see
 * https://www.ionicframework.com/docs/theming/
 */

/*
 * Ionic Colors
 * ----------------------------------------------------------------------------
 * Named colors make it easy to reuse colors on various components.
 * It's highly recommended to change the default colors
 * to match your app's branding. Ionic provides nine layered colors
 * that can be changed to theme an app. Additional colors can be
 * added as well (see below). For more information, please see
 * https://www.ionicframework.com/docs/theming/colors
 *
 * To easily create custom color palettes for your app’s UI,
 * check out our color generator:
 * https://www.ionicframework.com/docs/theming/color-generator
 */

 :root {
    --ion-color-primary: #FF3372;
	--ion-color-primary-rgb: 255,51,114;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #e02d64;
	--ion-color-primary-tint: #ff4780;

  --ion-color-secondary: #541D64;
	--ion-color-secondary-rgb: 84,29,100;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #4a1a58;
	--ion-color-secondary-tint: #653474;

	--ion-color-tertiary: #FFB413;
	--ion-color-tertiary-rgb: 255,180,19;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #e09e11;
	--ion-color-tertiary-tint: #ffbc2b;

  --ion-color-darkblue: #315282;
	--ion-color-darkblue-rgb: 49,82,130;
	--ion-color-darkblue-contrast: #ffffff;
	--ion-color-darkblue-contrast-rgb: 255,255,255;
	--ion-color-darkblue-shade: #2b4872;
	--ion-color-darkblue-tint: #46638f;

	--ion-color-lightblue: #00E8DC;
	--ion-color-lightblue-rgb: 0,232,220;
	--ion-color-lightblue-contrast: #000000;
	--ion-color-lightblue-contrast-rgb: 0,0,0;
	--ion-color-lightblue-shade: #00ccc2;
	--ion-color-lightblue-tint: #1aeae0;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

      
	--ion-color-accent: #ddd;
	--ion-color-accent-rgb: 221,221,221;
	--ion-color-accent-contrast: #000000;
	--ion-color-accent-contrast-rgb: 0,0,0;
	--ion-color-accent-shade: #c2c2c2;
	--ion-color-accent-tint: #e0e0e0;

  --ion-color-link: #FF3372;
	--ion-color-link-rgb: 255,51,114;
	--ion-color-link-contrast: #ffffff;
	--ion-color-link-contrast-rgb: 255,255,255;
	--ion-color-link-shade: #e02d64;
	--ion-color-link-tint: #ff4780;
}


/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

 :root {

  --ion-color-text1: #222;
  --ion-color-text2: rgb(0,0,0,.6);
  --ion-color-text3: rgb(0,0,0,.4);
  --ion-color-text4: rgb(0,0,0,.2);

  --ion-color-darkest: #FFF;

  --ion-background-color: #FFF;

	// --text-color: #222;
	// --text-color-1: #253985;
	// --text-color-invert: #FFF;
	// --text-color-2: rgb(0,0,0,.6);
	// --text-color-3: rgb(0,0,0,.4);




  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105,187,123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255,255,255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29,161,244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89,86,216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255,255,255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-linkedin: #0077B5;
  --ion-color-linkedin-rgb: 0,119,181;
  --ion-color-linkedin-contrast: #ffffff;
  --ion-color-linkedin-contrast-rgb: 255,255,255;
  --ion-color-linkedin-shade: #00699f;
  --ion-color-linkedin-tint: #1a85bc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35,182,234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255,255,255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-linkedin {
  --ion-color-base: var(--ion-color-linkedin);
  --ion-color-base-rgb: var(--ion-color-linkedin-rgb);
  --ion-color-contrast: var(--ion-color-linkedin-contrast);
  --ion-color-contrast-rgb: var(--ion-color-linkedin-contrast-rgb);
  --ion-color-shade: var(--ion-color-linkedin-shade);
  --ion-color-tint: var(--ion-color-linkedin-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211F1F;
  --ion-color-base-rgb: 33,31,31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537BC;
  --ion-color-base-rgb: 149,55,188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

.ion-color-accent {
  --ion-color-base: var(--ion-color-accent);
  --ion-color-base-rgb: var(--ion-color-accent-rgb);
  --ion-color-contrast: var(--ion-color-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-accent-shade);
  --ion-color-tint: var(--ion-color-accent-tint);
}

.ion-color-darkblue {
  --ion-color-base: var(--ion-color-darkblue);
  --ion-color-base-rgb: var(--ion-color-darkblue-rgb);
  --ion-color-contrast: var(--ion-color-darkblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkblue-shade);
  --ion-color-tint: var(--ion-color-darkblue-tint);
}

.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue);
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
  --ion-color-contrast: var(--ion-color-lightblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightblue-shade);
  --ion-color-tint: var(--ion-color-lightblue-tint);
}

.ion-color-link {
  --ion-color-base: var(--ion-color-link);
  --ion-color-base-rgb: var(--ion-color-link-rgb);
  --ion-color-contrast: var(--ion-color-link-contrast);
  --ion-color-contrast-rgb: var(--ion-color-link-contrast-rgb);
  --ion-color-shade: var(--ion-color-link-shade);
  --ion-color-tint: var(--ion-color-link-tint);
}


/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 800;
  --ion-font-family: "montserrat", sans-serif;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {

}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {

}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #FF3372;
	--ion-color-primary-rgb: 255,51,114;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #e02d64;
	--ion-color-primary-tint: #ff4780;

	--ion-color-secondary: #541D64;
	--ion-color-secondary-rgb: 84,29,100;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #4a1a58;
	--ion-color-secondary-tint: #653474;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-link: #FFB413;
	--ion-color-link-rgb: 255,180,19;
	--ion-color-link-contrast: #000000;
	--ion-color-link-contrast-rgb: 0,0,0;
	--ion-color-link-shade: #e09e11;
	--ion-color-link-tint: #ffbc2b;

  
  //  Added
  --ion-color-text1: #FFF;
  --ion-color-text2: rgb(255,255,255,.6);
  --ion-color-text3: rgb(255,255,255,.4);
  --ion-color-text4: rgb(255,255,255,.2);

  --ion-color-darkest: #101010;
  

  // --ion-color-accent: #2a2a2a;
  // --ion-color-accent-rgb: 42,42,42;
  // --ion-color-accent-contrast: #ffffff;
  // --ion-color-accent-contrast-rgb: 255,255,255;
  // --ion-color-accent-shade: #252525;
  // --ion-color-accent-tint: #3f3f3f;

  --ion-color-accent: #333333;
	--ion-color-accent-rgb: 51,51,51;
	--ion-color-accent-contrast: #ffffff;
	--ion-color-accent-contrast-rgb: 255,255,255;
	--ion-color-accent-shade: #2d2d2d;
	--ion-color-accent-tint: #474747;


  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #121212;

  --ion-tab-bar-background: #121212;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

// .dark-theme.ios {
//   --ion-background-color: #000000;
//   --ion-background-color-rgb: 0,0,0;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-color-step-50: #0d0d0d;
//   --ion-color-step-100: #1a1a1a;
//   --ion-color-step-150: #262626;
//   --ion-color-step-200: #333333;
//   --ion-color-step-250: #404040;
//   --ion-color-step-300: #4d4d4d;
//   --ion-color-step-350: #595959;
//   --ion-color-step-400: #666666;
//   --ion-color-step-450: #737373;
//   --ion-color-step-500: #808080;
//   --ion-color-step-550: #8c8c8c;
//   --ion-color-step-600: #999999;
//   --ion-color-step-650: #a6a6a6;
//   --ion-color-step-700: #b3b3b3;
//   --ion-color-step-750: #bfbfbf;
//   --ion-color-step-800: #cccccc;
//   --ion-color-step-850: #d9d9d9;
//   --ion-color-step-900: #e6e6e6;
//   --ion-color-step-950: #f2f2f2;

//   --ion-toolbar-background: #0d0d0d;

//   --ion-item-background: #000000;
// }


/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

// .dark-theme.md {
//   --ion-background-color: #121212;
//   --ion-background-color-rgb: 18,18,18;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-border-color: #222222;

//   --ion-color-step-50: #1e1e1e;
//   --ion-color-step-100: #2a2a2a;
//   --ion-color-step-150: #363636;
//   --ion-color-step-200: #414141;
//   --ion-color-step-250: #4d4d4d;
//   --ion-color-step-300: #595959;
//   --ion-color-step-350: #656565;
//   --ion-color-step-400: #717171;
//   --ion-color-step-450: #7d7d7d;
//   --ion-color-step-500: #898989;
//   --ion-color-step-550: #949494;
//   --ion-color-step-600: #a0a0a0;
//   --ion-color-step-650: #acacac;
//   --ion-color-step-700: #b8b8b8;
//   --ion-color-step-750: #c4c4c4;
//   --ion-color-step-800: #d0d0d0;
//   --ion-color-step-850: #dbdbdb;
//   --ion-color-step-900: #e7e7e7;
//   --ion-color-step-950: #f3f3f3;

//   --ion-item-background: #1e1e1e;

//   --ion-toolbar-background: #1f1f1f;

//   --ion-tab-bar-background: #1f1f1f;
// }
